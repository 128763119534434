<template>
  <div>
    <a-modal
      v-model="Show"
      title="查看人工处理结果"
      width="800px"
      footer
      @cancel="cancel"
    >
      <div style="border:1px solid black"></div>
      <div style="width: 100%; height: 100%">
        <h2 style="width:100%;text-align:center">企业端提出人工处理信息</h2>
        <p>问题描述：{{ details.questionTitle }}</p>
        <p>电话号码：{{ details.telephone }}</p>
        <p>详细描述：<span v-html="details.questionComment" style="display: inline-block;"></span></p>
      </div>
      <div style="border:1px solid black"></div>
      <div style="width: 100%; height: 100%">
        <h2 style="width:100%;text-align:center">平台端人工处理信息</h2>
        <p>人工处理意见：<span v-html="details.consequence" style="display: inline-block;"></span></p>
      </div>
    </a-modal>
  </div>
</template>
<script >
export default {
  name: 'Index',
  data () {
    return {
      Show: false,
      details: {}
    }
  },
  created () {
  },
  methods: {
    detail (val) {
      this.details = val
      console.log(val)
      this.Show = true
    },
    cancel () {
      this.details = {}
      this.Show = false
    }
  },
  destroyed () {
  }
}
</script>
<style lang="less" scoped>
.div_size{
    font-size:18px;
    margin-bottom: 20px;
}
</style>
